<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">退货单 - 已完成</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-date-picker v-model="date" size="small" type="daterange" unlink-panels
                      :clearable="false" class="el-date-picker family"
                      style="width: 240px !important;margin-right: 10px"
                      range-separator="/" format="yyyy-MM-dd" value-format="timestamp" :editable="false"/>
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectBack" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectBack" clearable maxlength="20"
                style="width: 300px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectBack">查询</el-button>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>

        <span class="span" style="color: #606266;margin-left: 30px">退数 </span>
        <span class="span" style="color: #409EFF">{{countnum}}</span>
        <span class="span" style="color: #606266;margin-left: 30px">书款 </span>
        <span class="span" style="color: #409EFF">{{parseFloat(countprice).toFixed(2)}}</span>
        <span class="span" style="color: #606266;margin-left: 30px">退款 </span>
        <span class="span" style="color: #EB6100">{{parseFloat(countpay).toFixed(2)}}</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="backs" :style="{width: '961px'}"
              :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}">
      <el-table-column label="退货时间" prop="time" width="100" :resizable="false"
                       :formatter="btimeFormat"/>
      <el-table-column label="订单号 / 订货时间 / 供货商" width="230" :resizable="false">
        <template slot-scope="scope">
          <span style="margin-right: 25px">{{'#' + (Array(7).join('0') + scope.row.order.id).slice(-7)}}</span>
          <span>{{otimeFormat(scope.row.order.time)}}</span>
          <el-divider/>
          <span style="margin-right: 5px">{{scope.row.order.seller.name}}</span>
          <el-tag type="warning">{{payFormat(scope.row.order.seller.pay)}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="书号 / 定价 / 书名" width="320" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <div style="float:left">
              <span style="margin-right: 25px">{{scope.row.book.isbn}}</span>
              <span style="margin-right: 25px">{{'￥'+priceFormat(scope.row.book.price)}}</span>
              <el-divider/>
              <span>{{scope.row.book.title}}</span>
            </div>
            <span v-if="scope.row.comment!==undefined && scope.row.comment!==''"
                  class="comment">网店备注：{{scope.row.comment}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="折扣 / 库位" width="125" :resizable="false">
        <template slot-scope="scope">
          <span>{{priceFormat(scope.row.order.detail.discount)}}</span>
          <el-divider/>
          <span :style="{color: scope.row.order.detail.position===undefined?'#C0C4CC':'#606266'}">
            {{scope.row.order.detail.position===undefined?
            '暂无库位':scope.row.order.detail.position}}</span>
        </template>
      </el-table-column>
      <el-table-column label="退数 / 书款" width="85" :resizable="false">
        <template slot-scope="scope">
          <span>{{scope.row.backNum}}</span>
          <el-divider/>
          <span>{{priceFormat(scope.row.backPrice)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="退款信息" width="100" :resizable="false">
        <template slot-scope="scope">
          <div v-if="scope.row.account">
            <div v-if="scope.row.cashPrice>0">
              <el-tag type="warning">现款</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.cashPrice)}}</span>
            </div>
            <div v-if="scope.row.accountPrice>0">
              <el-tag type="warning">记账</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.accountPrice)}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    created () {
      this.id = sessionStorage.getItem('orderbackId')
      if (this.id !== null) {
        sessionStorage.clear()
        this.getBack(this.id)
      } else {
        this.selectBack()
      }
    },
    data () {
      return {
        query: {
          begin: '',
          end: '',
          name: '',
          type: 'isbn',
          value: '',
          page: 1
        },
        backs: [],
        total: 0,
        countnum: 0,
        countprice: 0,
        countpay: 0,
        date: [Date.now() - 29 * 86400000, Date.now()],
        id: null
      }
    },
    methods: {
      btimeFormat (row) {
        const date = new Date(row.time)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      otimeFormat (value) {
        const date = new Date(value)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      payFormat (value) {
        if (value === '0') {
          return '日结'
        } else if (value === '1') {
          return '月结'
        } else if (value === '2') {
          return '预付'
        }
      },

      typeChange () {
        this.query.value = ''
      },
      selectBack () {
        this.query.page = 1
        this.getBackList()
      },
      pageChange (page) {
        this.query.page = page
        this.getBackList()
      },

      async getBack (id) {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getComplateBack',
            { params: { id: id } })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.backs = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.backNum
          this.countprice = res.data.count.backPrice
          this.countpay = res.data.count.accountPrice
          this.backs = res.data.data

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.backs = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getBackList () {
        this.query.begin = this.date[0]
        this.query.end = this.date[1]
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getComplateBackList', { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.backs = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.backNum
          this.countprice = res.data.count.backPrice
          this.countpay = res.data.count.accountPrice
          this.backs = res.data.data

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.backs = []
          loading.close()
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText1 {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
    color: #67C23A;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  .el-divider--horizontal {
    margin: 1px 0;
    height: 1px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-divider--vertical {
    margin: 0 20px;
  }

  .comment {
    color: #EB6100;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.85);
  }
</style>
